// MatrixRain.js
import React, { useRef, useEffect } from 'react';
import './MatrixRain.css';

const MatrixRain = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Adjust canvas size on window resize
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    const fontSize = 14;
    const columns = canvas.width / fontSize;
    const drops = Array(Math.floor(columns)).fill(1);

    // Character set for Matrix effect
    const matrixChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    let startTime = Date.now();
    const PHASE_DURATION = 18000; // 18 seconds per phase
    const TRANSITION_DURATION = 6000; // 6-second crossfade

    // WarGames specific elements
    const warGamesText = [
      'WOULD YOU LIKE TO PLAY A GAME?',
      'GLOBAL THERMONUCLEAR WAR',
      'DEFCON 4',
      'MISSILE COMMAND ACTIVE',
      'TRACKING TARGETS...',
      'USSR ICBMs DETECTED: 47',
      'US DEFENSE SYSTEMS: ACTIVE'
    ];
    let typeIndex = 0;
    let charIndex = 0;
    let lastTypeTime = 0;

    // Missile data for WarGames effect
    let missiles = [];
    let explosions = []; // For explosion effects
    let radarImpacts = []; // For radar pulse effects

    const drawWarGames = (ctx, opacity) => {
      ctx.save();
      ctx.globalAlpha = opacity;

      // Draw grid background
      ctx.strokeStyle = 'rgba(0, 255, 0, 0.1)';
      ctx.lineWidth = 1;
      const gridSize = 40;

      for (let x = 0; x < canvas.width; x += gridSize) {
        for (let y = 0; y < canvas.height; y += gridSize) {
          ctx.strokeRect(x, y, gridSize, gridSize);
          // Reduce flickering numbers and their frequency
          if (Math.random() < 0.0005) {
            ctx.fillText(
              `${Math.floor(x / gridSize)},${Math.floor(y / gridSize)}`,
              x + 5,
              y + 14
            );
          }
        }
      }

      // Typing effect for main text
      const currentTime = Date.now();
      if (currentTime - lastTypeTime > 200) {
        charIndex++;
        lastTypeTime = currentTime;
        if (charIndex > warGamesText[typeIndex].length) {
          if (currentTime - lastTypeTime > 4000) {
            typeIndex = (typeIndex + 1) % warGamesText.length;
            charIndex = 0;
          }
        }
      }

      // Draw current typing text
      ctx.fillStyle = '#00ff00';
      ctx.font = '20px Courier';
      ctx.fillText(
        warGamesText[typeIndex].substring(0, charIndex),
        canvas.width / 2 - 200,
        canvas.height / 2
      );

      // Draw status information
      ctx.font = '14px Courier';
      ctx.fillText(`DEFCON STATUS: ${Math.floor(Date.now() / 1000) % 5 + 1}`, 20, 30);
      ctx.fillText(`LAT: 38°51'N  LON: 77°02'W`, 20, 50);
      ctx.fillText(`NORAD CMOC STATUS: ACTIVE`, 20, 70);

      // Launch missiles more frequently
      if (Math.random() < 0.05) {
        missiles.push({
          x: Math.random() * canvas.width,
          y: canvas.height,
          targetX: Math.random() * canvas.width,
          targetY: 0,
          trail: []
        });
      }

      // Draw missiles with tracers
      ctx.strokeStyle = '#00ff00'; // Green color for missiles
      missiles.forEach((missile, index) => {
        // Calculate missile position
        const dx = missile.targetX - missile.x;
        const dy = missile.targetY - missile.y;
        const dist = Math.sqrt(dx * dx + dy * dy);
        const speed = 2;
        missile.x += (dx / dist) * speed;
        missile.y += (dy / dist) * speed;

        // Store trail positions
        missile.trail.push({ x: missile.x, y: missile.y });
        if (missile.trail.length > 10) {
          missile.trail.shift();
        }

        // Draw trail
        ctx.beginPath();
        ctx.moveTo(missile.trail[0].x, missile.trail[0].y);
        missile.trail.forEach((pos) => {
          ctx.lineTo(pos.x, pos.y);
        });
        ctx.stroke();

        // Remove missile if it reaches the target
        if (missile.y <= missile.targetY) {
          // Add explosion at target
          explosions.push({
            x: missile.targetX,
            y: missile.targetY,
            radius: 0,
            maxRadius: 50, // Increased explosion size
            alpha: 1
          });
          missiles.splice(index, 1);
        }
      });

      // Draw explosions
      explosions.forEach((explosion, index) => {
        ctx.beginPath();
        ctx.arc(explosion.x, explosion.y, explosion.radius, 0, 2 * Math.PI);
        ctx.fillStyle = `rgba(0, 255, 0, ${explosion.alpha})`;
        ctx.fill();

        // Update explosion
        explosion.radius += 1; // Increased expansion speed
        explosion.alpha -= 0.01;

        // Remove explosion when it's done
        if (explosion.alpha <= 0) {
          explosions.splice(index, 1);
        }
      });

      // Create more radar impacts
      if (Math.random() < 0.01) {
        radarImpacts.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          radius: 0,
          maxRadius: 200,
          alpha: 0.5
        });
      }

      // Draw radar impacts
      radarImpacts.forEach((impact, index) => {
        ctx.beginPath();
        ctx.arc(impact.x, impact.y, impact.radius, 0, 2 * Math.PI);
        ctx.strokeStyle = `rgba(0, 255, 0, ${impact.alpha})`;
        ctx.lineWidth = 2;
        ctx.stroke();

        // Update impact
        impact.radius += 2;
        impact.alpha -= 0.005;

        // Remove impact when it fades out
        if (impact.alpha <= 0) {
          radarImpacts.splice(index, 1);
        }
      });

      ctx.restore();
    };

    // Space Invaders effect
    const alienShapes = [
      // Authentic Space Invaders shapes
      // Alien Type 1
      [
        [0,0,1,0,0],
        [0,1,0,1,0],
        [1,1,1,1,1],
        [1,0,1,0,1],
        [1,0,0,0,1],
      ],
      // Alien Type 2
      [
        [0,0,1,0,0],
        [0,1,1,1,0],
        [1,1,1,1,1],
        [1,0,1,0,1],
        [1,0,1,0,1],
      ],
      // Alien Type 3
      [
        [0,1,1,1,0],
        [1,0,1,0,1],
        [1,1,1,1,1],
        [0,1,1,1,0],
        [0,1,0,1,0],
      ]
    ];

    let aliens = [];
    let alienMoveDirection = 1; // 1 for right, -1 for left
    let lastAlienMoveTime = 0;

    // Player ship data
    let playerShipX = canvas.width / 2;
    let playerShipDirection = 1; // 1 for right, -1 for left
    let lastPlayerMoveTime = 0;

    const drawSpaceInvaders = (ctx, phaseElapsed, opacity) => {
      ctx.save();
      ctx.globalAlpha = opacity;

      const invaderPhaseDuration = PHASE_DURATION + TRANSITION_DURATION; // 24 seconds

      // Determine fade in/out progress
      let fadeInProgress = 1; // Default to fully visible
      let holdTime = 0;

      if (phaseElapsed < TRANSITION_DURATION) {
        // Fade In period
        fadeInProgress = phaseElapsed / TRANSITION_DURATION;
      } else if (phaseElapsed < PHASE_DURATION) {
        // Hold period
        holdTime = phaseElapsed - TRANSITION_DURATION; // Time since hold started
      } else {
        // Fade Out period
        fadeInProgress = 1 - (phaseElapsed - PHASE_DURATION) / TRANSITION_DURATION;
      }

      // Apply fade-in progress to globalAlpha
      ctx.globalAlpha *= fadeInProgress;

      // Initialize aliens ONLY at the very start of the phase
      if (aliens.length === 0 && holdTime < 100) {  // Only initialize in first 100ms
        aliens = [];
        const rows = 5;
        const cols = 11;
        const startX = (canvas.width - cols * 40) / 2;
        const startY = 100;
        for (let row = 0; row < rows; row++) {
          for (let col = 0; col < cols; col++) {
            aliens.push({
              shape: alienShapes[row % alienShapes.length],
              x: startX + col * 40,
              y: startY + row * 40,
              exploded: false
            });
          }
        }
      }

      // Move aliens every 500ms
      const currentTime = Date.now();
      if (currentTime - lastAlienMoveTime > 500) {
        aliens.forEach((alien) => {
          alien.x += alienMoveDirection * 10;
        });

        // Check for edge collision
        const edgeAlien = alienMoveDirection === 1
          ? Math.max(...aliens.map(a => a.x))
          : Math.min(...aliens.map(a => a.x));

        if (edgeAlien <= 0 || edgeAlien >= canvas.width - 40) {
          alienMoveDirection *= -1;
          aliens.forEach((alien) => {
            alien.y += 20; // Move down when changing direction
          });
        }

        lastAlienMoveTime = currentTime;
      }

      // Move player ship randomly
      if (currentTime - lastPlayerMoveTime > 100) {  // Update every 100ms
        playerShipX += (Math.random() - 0.5) * 10;  // Random movement between -5 and +5 pixels
        lastPlayerMoveTime = currentTime;
      }

      // Start explosions earlier (at 6 seconds into hold) with more random timing
      if (holdTime > 6000 && holdTime < 15000) {  // 9-second window for explosions
        aliens.forEach((alien) => {
          if (!alien.exploded && Math.random() < 0.03) {  // More random timing
            alien.exploded = true;
            alien.explosionParts = [];
            // Create fewer, more random explosion particles
            for (let i = 0; i < 8; i++) {
              alien.explosionParts.push({
                x: alien.x + 20,
                y: alien.y + 20,
                vx: (Math.random() - 0.5) * 8,  // Faster, more random directions
                vy: (Math.random() - 0.5) * 8,
                alpha: 1  // Start fully visible
              });
            }
          }
        });
      }

      // Draw exploding aliens with quick fade
      aliens.forEach((alien) => {
        if (!alien.exploded) {
          // Draw normal alien
          alien.shape.forEach((row, i) => {
            row.forEach((pixel, j) => {
              if (pixel) {
                ctx.fillRect(alien.x + j * 8, alien.y + i * 8, 8, 8);
              }
            });
          });
        } else {
          // Draw explosion particles with fade
          alien.explosionParts.forEach((part, index) => {
            ctx.globalAlpha = part.alpha;
            ctx.fillRect(part.x, part.y, 4, 4);  // Smaller particles
            part.x += part.vx;
            part.y += part.vy;
            part.alpha -= 0.1;  // Faster fade out
            if (part.alpha <= 0) {
              alien.explosionParts.splice(index, 1);
            }
          });
        }
      });

      // Show GAME OVER exactly at 15 seconds (end of hold)
      if (phaseElapsed >= PHASE_DURATION) {
        ctx.save();
        const fadeOutProgress = (phaseElapsed - PHASE_DURATION) / TRANSITION_DURATION;
        ctx.globalAlpha = 1 - fadeOutProgress; // Fade out during transition
        ctx.font = '60px "Press Start 2P", Courier';
        ctx.fillStyle = '#00ff00';
        const text = 'GAME OVER';
        const textWidth = ctx.measureText(text).width;
        ctx.fillText(text, (canvas.width - textWidth) / 2, canvas.height / 2);
        ctx.restore();
      }

      // Draw ground line
      ctx.fillStyle = '#00ff00';
      ctx.fillRect(0, canvas.height - 50, canvas.width, 2);

      // Draw score and lives
      ctx.font = '20px "Press Start 2P", Courier';
      ctx.fillText('SCORE<1> HI-SCORE SCORE<2>', canvas.width / 2 - 280, 50);
      ctx.fillText(
        `${Math.floor(phaseElapsed / 100)}0    ${Math.floor(phaseElapsed / 50)}00    0`,
        canvas.width / 2 - 250,
        80
      );

      // Only draw player ship and allow movement before GAME OVER
      if (holdTime < 15000) {
        // Player ship movement and drawing code here
        // ... existing player ship code ...
      }

      // Draw player ship above ground line and add movement
      ctx.fillRect(playerShipX - 20, canvas.height - 70, 40, 15); // Ship body
      ctx.fillRect(playerShipX - 5, canvas.height - 85, 10, 15); // Ship turret

      // Draw player shots
      ctx.fillStyle = '#00ff00';
      if (Math.random() < 0.1) {
        ctx.fillRect(playerShipX - 1, canvas.height - 90, 2, 10);
      }

      // Enemy shots
      aliens.forEach((alien) => {
        if (!alien.exploded && Math.random() < 0.01) {
          ctx.fillRect(alien.x + 16, alien.y + 40, 2, 10);
        }
      });

      ctx.restore();
    };

    const drawMatrix = (ctx, opacity) => {
      ctx.save();
      ctx.globalAlpha = opacity;
      for (let i = 0; i < drops.length; i++) {
        const char = matrixChars[Math.floor(Math.random() * matrixChars.length)];
        ctx.fillText(char, i * fontSize, drops[i] * fontSize);

        if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }
        drops[i]++;
      }
      ctx.restore();
    };

    const draw = () => {
      const currentTime = Date.now();
      const totalCycle = (PHASE_DURATION + TRANSITION_DURATION) * 3; // 72 seconds
      const elapsed = (currentTime - startTime) % totalCycle;
      const phaseTime = PHASE_DURATION + TRANSITION_DURATION;

      let currentPhase, nextPhase;
      let phaseElapsed;
      let transitionProgress = 0;

      if (elapsed < phaseTime) {
        // First phase: Matrix
        phaseElapsed = elapsed;
        currentPhase = 'matrix';
        nextPhase = 'wargames';
      } else if (elapsed < phaseTime * 2) {
        // Second phase: WarGames
        phaseElapsed = elapsed - phaseTime;
        currentPhase = 'wargames';
        nextPhase = 'spaceinvaders';
      } else {
        // Third phase: Space Invaders
        phaseElapsed = elapsed - phaseTime * 2;
        currentPhase = 'spaceinvaders';
        nextPhase = 'matrix';
      }

      if (phaseElapsed < PHASE_DURATION) {
        // Hold period
        transitionProgress = 0;
      } else {
        // Transition period
        transitionProgress = (phaseElapsed - PHASE_DURATION) / TRANSITION_DURATION;
      }

      // Clear canvas with adjusted opacity
      ctx.fillStyle = `rgba(26, 26, 26, 0.1)`;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#00ff00';
      ctx.font = `${fontSize}px Courier`;

      // Adjust opacity based on transition progress (min 0.1, max 0.6)
      const opacityRange = 0.6 - 0.1; // 0.5
      const currentOpacity = 0.1 + (1 - transitionProgress) * opacityRange;
      const nextOpacity = 0.1 + transitionProgress * opacityRange;

      // Draw current phase
      if (currentPhase === 'matrix') {
        drawMatrix(ctx, currentOpacity);
      } else if (currentPhase === 'wargames') {
        drawWarGames(ctx, currentOpacity);
      } else if (currentPhase === 'spaceinvaders') {
        drawSpaceInvaders(ctx, phaseElapsed, currentOpacity);
      }

      // Draw next phase during transition
      if (transitionProgress > 0) {
        if (nextPhase === 'matrix') {
          drawMatrix(ctx, nextOpacity);
        } else if (nextPhase === 'wargames') {
          drawWarGames(ctx, nextOpacity);
        } else if (nextPhase === 'spaceinvaders') {
          drawSpaceInvaders(ctx, 0, nextOpacity); // Start from beginning
        }
      }
    };

    const animationId = setInterval(draw, 50);
    return () => {
      clearInterval(animationId);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return <canvas ref={canvasRef} className="matrix-canvas fade-cycle"></canvas>;
};

export default MatrixRain;
