// App.js
import React from 'react';
import './App.css';
import Projects from './components/Projects';
import MatrixRain from './components/MatrixRain';
function App() {
  return (
    <div className="container">
     

      <div className="header-grid">
        <div className="header-item">
          <MatrixRain />
          <h1>Douglas R. Albright</h1>
          <h3>Studio Founder | Game Director | Creative Lead | Producer</h3>
        
        </div>
        <div className="header-item">
          <p>email: <a href="mailto:dr@signalstudios.net">dr@signalstudios.net</a></p>
          <p>X: <a href="https://twitter.com/toysoldiersgame">@toysoldiersgame</a></p>
          <p>github: <a href="https://github.com/mostlypeaceful">mostlypeaceful</a></p>
        </div>
      </div>

      <section>
    
        <h3>About Me</h3>  <a 
        href="/DouglasRAlbright.md" 
        download 
        className="download-button"
        title="Download Resume"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
          <polyline points="7 10 12 15 17 10" />
          <line x1="12" y1="15" x2="12" y2="3" />
        </svg>
      </a>

        <p>
          I have over 25 years of experience as a studio manager, project director, producer,
          developer, and creative lead at game studios and publishers including EA Seattle, EA Los
          Angeles, THQ, Zipper Interactive, and Snowblind Studios. I contributed to the development
          of over 20 AAA titles, including flagship franchises such as Need for Speed, Medal of
          Honor, and SOCOM.
        </p>
        <p>
          As the founder of Signal Studios, I led the creation, development, and release of 8
          original games, the most successful of which was <em>Toy Soldiers</em> (millions sold, two
          DICE Strategy Game of the Year nominations). In 2011, Signal Studios was named one of the
          top 30 game development studios worldwide. Signal Studios focused on developing 'AA' games
          for digital platforms such as Xbox Live (XBL), PlayStation Network (PSN), Steam, and
          mobile.
        </p>
      </section>

      <section>
        <h3>Experience</h3>
        <h4>Founder | President | Creative Director | Developer</h4>
        <p>
          <strong>Signal Studios</strong> – Seattle, WA (2007 – 2023)
        </p>
        <ul>
          <li>
            Founded and led Signal Studios, developing eight original titles, including the hit{' '}
            <em>Toy Soldiers</em> series, resulting in 4 million units sold and 15 million downloads across platforms like Xbox Live, PlayStation Network, and Steam.
          </li>
          <li>
            Built Signal Studios into a 55-person team and achieved over $40 million in revenue.
          </li>
          <li>
            Secured and managed partnerships with industry leaders, including Microsoft, Ubisoft,
            Gungho, IGT, Tilting Point Media, and Facebook.
          </li>
          <li>
            Developed and directed all aspects of game production, including programming, design,
            and visual development, using proprietary tools and industry-standard engines (Unity,
            Unreal Engine).
          </li>
        </ul>

        <h4>Lead and Senior Roles</h4>
        <p>
          <strong>EA, THQ, Zipper Interactive, Snowblind Studios</strong> (2001 – 2007)
        </p>
        <ul>
          <li>
            Contributed to major franchises (<em>Need for Speed, Medal of Honor, SOCOM</em>) and
            managed art pipelines across multiple studios.
          </li>
          <li>
            Developed assets and tools to enhance in-game visuals on titles like{' '}
            <em>Need for Speed</em> and <em>Medal of Honor</em>.
          </li>
          <li>
            Led the development of art tools and techniques for the Snowblind Engine on Xbox 360,
            PS3, and PC.
          </li>
          <li>
            Oversaw art teams and project schedules, driving high-quality output on projects like{' '}
            <em>SOCOM</em>.
          </li>
        </ul>
      </section>

      <section>
        <h3>Skills</h3>
        <ul>
          <li>
            <strong>Game Production & Creative Direction:</strong> Full-cycle game production, from
            concept through release, across console, PC, and mobile platforms.
          </li>
          <li>
            <strong>Team Leadership & Studio Management:</strong> Built and led teams of up to 55
            people, including artists, designers, engineers, and producers.
          </li>
          <li>
            <strong>Corporate Partnerships:</strong> Negotiated and managed strategic partnerships
            with Microsoft, Ubisoft, Facebook, and Gungho, securing over $25 million in funding.
          </li>
          <li>
            <strong>Art & Technical Direction:</strong> Directed art production for next-gen game
            engines, setting standards for visual fidelity and performance on various platforms.
          </li>
          <li>
            <strong>Technical Skills:</strong> Knowledgeable in C++, C#, and JS; extensive
            experience with Unity and Unreal Engine; expert in Autodesk Maya, Photoshop, ZBrush, 3ds
            Max, and Adobe Creative Suite.
          </li>
        </ul>
      </section>

      {/* Include the Projects component */}
      <Projects />
      <section>
      <h3>Status</h3>
      Drawing on my development experience and a deep fascination with history to embrace a new passion project. 
        The Toy Soldiers series combined historical themes and engaging gameplay—I am expanding these ideas into 
        a game about The Flight of the Nez Perce and the broader topic of the Indian Wars.
      <br /> <br /> I suspect the normal approach is no longer working. The methods by which games are being developed and monetized 
      is rapidly changing and I think its necassary to explore new approaches to bring these stories to life. My hands-on work 
      spans coding, art, AI, project management, and I am learning new things every day. I feel well-equipped for the task at hand.
      </section>
    </div>
  );
}

export default App;
